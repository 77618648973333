var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mx-1"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-7 py-7 rounded-lg",attrs:{"id":"card-custom"}},[_c('p',{staticClass:"headline-color"},[_vm._v("Data Persetujuan Perubahan Pegawai")]),_c('v-divider'),_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"my-0",attrs:{"cols":"12","xl":"3","lg":"3","md":"6","sm":"6","xs":"12"}},[_c('v-text-field',{attrs:{"placeholder":"Masukan Nama Pegawai","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"my-0",attrs:{"cols":"12","xl":"3","lg":"3","md":"6","sm":"6","xs":"12"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"#FBB005"},on:{"click":_vm.getHistoryList}},[_c('span',{staticClass:"subtitle-2 text-capitalize"},[_vm._v("Cari")])])],1)],1),_c('v-data-table',{staticClass:"mt-10",attrs:{"id":"table-custom","multi-sort":"","headers":_vm.headers,"items":_vm.employees,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItem,"footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems
          },"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.tgl_changed`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("date")(item.tgl_changed))+" ")]}},{key:`item.duedate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("date")(item.duedate))+" ")]}},{key:`item.levelApproved`,fn:function({ item }){return [(item.levelApproved == 1)?_c('div',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","text-color":"white"}},[_vm._v(" Telah Disetujui oleh Admin Satker ")])],1):(item.levelApproved == 2)?_c('div',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success","text-color":"white"}},[_vm._v(" Telah Disetujui oleh Admin Pusat ")])],1):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.getToPage('employee-updated.detail', {
                    params: { historyId: item.history_id },
                    query: {
                      NIP: item.NIP,
                      tbsource: item.tbsource
                    }
                  })}}},[_vm._v("LIHAT")]),(_vm.isApprovePegawai)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"success","loading":_vm.approveLoading},on:{"click":function($event){return _vm.handleChange(item, 'approve')}}},[_vm._v("SETUJUI")]):_vm._e(),(_vm.isDeclinePegawai)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"error","loading":_vm.declineLoading},on:{"click":function($event){return _vm.handleChange(item, 'decline')}}},[_vm._v("TOLAK")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('ContentNotFound',{attrs:{"message":"Data pegawai dan presensi tidak ada"}})]},proxy:true}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }