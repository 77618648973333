<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" class="py-0">
        <v-card class="px-7 py-7 rounded-lg" id="card-custom">
          <p class="headline-color">Data Persetujuan Perubahan Pegawai</p>
          <v-divider />
          <v-row align="end">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-text-field
                placeholder="Masukan Nama Pegawai"
                v-model="search"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-btn color="#FBB005" class="px-10" @click="getHistoryList">
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            class="mt-10"
            multi-sort
            :headers="headers"
            :items="employees"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.tgl_changed`]="{ item }">
              {{ item.tgl_changed | date }}
            </template>
            <template v-slot:[`item.duedate`]="{ item }">
              {{ item.duedate | date }}
            </template>
            <template v-slot:[`item.levelApproved`]="{ item }">
              <div v-if="item.levelApproved == 1">
                <v-chip class="ma-2" color="success" text-color="white">
                  Telah Disetujui oleh Admin Satker
                </v-chip>
              </div>
              <div v-else-if="item.levelApproved == 2">
                <v-chip class="ma-2" color="success" text-color="white">
                  Telah Disetujui oleh Admin Pusat
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-card-actions class="pa-0">
                <v-btn
                  small
                  color="primary"
                  @click="
                    getToPage('employee-updated.detail', {
                      params: { historyId: item.history_id },
                      query: {
                        NIP: item.NIP,
                        tbsource: item.tbsource
                      }
                    })
                  "
                  >LIHAT</v-btn
                >
                <v-btn
                  v-if="isApprovePegawai"
                  class="ml-1"
                  small
                  color="success"
                  :loading="approveLoading"
                  @click="handleChange(item, 'approve')"
                  >SETUJUI</v-btn
                >
                <v-btn
                  v-if="isDeclinePegawai"
                  class="ml-1"
                  small
                  color="error"
                  :loading="declineLoading"
                  @click="handleChange(item, 'decline')"
                  >TOLAK</v-btn
                >
              </v-card-actions>
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Data pegawai dan presensi tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
const ContentNotFound = () => import("@/components/Content/NotFound");
import { mapGetters } from "vuex";
import { ACTION_ACCESS } from "@/common/constant";

export default {
  components: {
    ContentNotFound
  },
  data() {
    return {
      type: null,
      declineLoading: false,
      approveLoading: false,
      headers: [
        { text: "NIP", value: "NIP", sortable: true },
        { text: "NAMA", value: "nama", sortable: true },
        { text: "PENGUBAHAN DATA", value: "type_changed", sortable: false },
        { text: "TANGGAL PENGUBAHAN", value: "tgl_changed", sortable: false },
        { text: "JENIS", value: "action_changed", sortable: false },
        { text: "OLEH", value: "user_changed", sortable: false },
        { text: "BATAS AKHIR", value: "duedate", sortable: false },
        { text: "AKSI", value: "actions", sortable: false }
      ],
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      employees: [],

      // Form
      search: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isApprovePegawai() {
      return ACTION_ACCESS.PERUBAHAN_DATA_PEGAWAI.APPROVE.find(
        d => d == this.currentUser.role
      );
    },
    isDeclinePegawai() {
      return ACTION_ACCESS.PERUBAHAN_DATA_PEGAWAI.DECLINE.find(
        d => d == this.currentUser.role
      );
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getHistoryList);
      },
      deep: true
    }
  },
  methods: {
    handleChange(item, type = "approve") {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan ${
          type == "approve" ? "menyetujui" : "menolak"
        } perubahan ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              history_id: item.history_id,
              NIP: item.NIP,
              isApproved: type == "approve" ? 1 : 2,
              tbsource: item.tbsource
            };
            this.handleApprove(payload, type);
          }
        }
      });
    },
    // Service
    async getHistoryList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await PegawaiService.getApprovalList({
          filter: {
            search: this.search || ""
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let employees = list;
              employees.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.employees = employees;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async handleApprove(data, type = "approve") {
      try {
        this[type + "Loading"] = true;
        await PegawaiService.approve(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getHistoryList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[type + "Loading"] = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
